import React from "react";

const ClaimInfo = () => {
  return (
    <div>Walmart and the Walmart Foundation generally provide more than $1 billion in cash and in-kind to support programs that align with our philanthropic priorities. We focus on areas where we can do the most good - combining the unique strengths of the business alongside our philanthropy. Our ability to draw on Walmart business strengths, providing more than just funding, helps our philanthropy to deliver greater societal impact. Today, Walmart.org, through the combined philanthropic efforts of both Walmart and the Walmart Foundation, creates opportunities for people to live better every day.
      <br />
      <br />
      We are working to assist individuals and small businesses that have been impacted by the Coronavirus (COVID-19). The ongoing evolution of the pandemic is prompting officials and corporations to provide emergency assistance and funding to help out individuals and small businesses who are experiencing economic hardship. While the details of programs are still evolving, there are current funds and resources that are available for individuals and small businesses now.
      <br />
      <br /><b>* Required</b>
      <br />​Your identity must be verified before you are allowed to set up a benefit payment method: Direct Deposit or Debit Card. Please be sure to enter your personal information exactly as it is shown on your Driver's License or State ID.
      <br />

    </div>
  );
};

export default ClaimInfo;
