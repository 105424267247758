import React, { useEffect } from "react";
import { Button, Card, Col, Container, Row, Spinner } from "react-bootstrap";
import { Form, Formik } from "formik";
import { Form as BootstrapForm } from "react-bootstrap";
import * as Yup from "yup";
import FormControl from "../components/FormControl";
import ReCAPTCHA from "react-google-recaptcha";
import RadioButtons from "../components/RadioButtons";
import axios from "axios";
import Earn450Info from "../components/earn450info";
const Earn450 = () => {
  useEffect(() => {
    document.title = "EARN $450 WEEKLY BY WORKING AT HOME WITH FLEXIBLE TIME";
  }, []);
  const initialValues = {
    firstname: "",
    lastname: "",
    streetaddress: "",
    city: "",
    state: "",
    postal: "",
    cellphone: "",
    email: "",
    country: "United States",
    estatus: "",
    recaptcha: "",
    form: "EARN450_WEEKLY_BY_WORKING_AT_HOME_WITH_FLEXIBLE_TIME",
  };

  const radioOptions = [
    { key: "Employeed", value: "employeed" },
    { key: "Umeployeed", value: "unemployeed" },
  ];

  const validationSchema = Yup.object({
    firstname: Yup.string().required(),
    lastname: Yup.string().required(),
    streetaddress: Yup.string().required(),
    city: Yup.string().required(),
    state: Yup.string().required(),
    postal: Yup.string()
      .required()
      .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, "Please Enter Valid USA Zip Code"),
    cellphone: Yup.number().required(),
    email: Yup.string().email().required(),
    recaptcha: Yup.string().required(),
    estatus: Yup.string().required(),
  });

  const onSubmit = async (values, helpers) => {
    // console.log(values);
    await axios.post(
      "https://oeun2av184.execute-api.us-east-1.amazonaws.com/default/FormNew",
      JSON.stringify(values),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    window.location = "/thanks";
  };

  return (
    <Container>
      <Row>
        <Col lg={{ span: 10, offset: 1 }}>
          <Card className="mt-5">
            <Card.Header>EARN $450 WEEKLY BY WORKING AT HOME WITH FLEXIBLE TIME</Card.Header>
            <Card.Body>
              <Earn450Info />
              <div className="mb-3"></div>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ isSubmitting, isValid, dirty, setFieldValue }) => (
                  <Form>
                    <h5>Name</h5>
                    <Row>
                      <Col>
                        <BootstrapForm.Group controlId="firstname">
                          <FormControl
                            type="text"
                            name="firstname"
                            placeholder="First Name"
                            tabIndex={1}
                          />
                        </BootstrapForm.Group>
                      </Col>
                      <Col>
                        <BootstrapForm.Group controlId="lastname">
                          <FormControl
                            type="text"
                            name="lastname"
                            placeholder="Last Name"
                            tabIndex={2}
                          />
                        </BootstrapForm.Group>
                      </Col>
                    </Row>

                    <h5>Address</h5>
                    <BootstrapForm.Group controlId="streetaddress">
                      <FormControl
                        type="text"
                        name="streetaddress"
                        placeholder="Street Address"
                        tabIndex={3}
                      />
                    </BootstrapForm.Group>
                    <Row>
                      <Col>
                        <BootstrapForm.Group controlId="city">
                          <FormControl type="text" name="city" placeholder="City" tabIndex={4} />
                        </BootstrapForm.Group>
                      </Col>
                      <Col>
                        <BootstrapForm.Group controlId="state">
                          <FormControl type="text" name="state" placeholder="State" tabIndex={5} />
                        </BootstrapForm.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <BootstrapForm.Group controlId="postal">
                          <FormControl
                            type="text"
                            name="postal"
                            placeholder="Postal"
                            tabIndex={6}
                          />
                        </BootstrapForm.Group>
                      </Col>
                      <Col>
                        <BootstrapForm.Group controlId="country">
                          <FormControl
                            type="text"
                            name="country"
                            placeholder="Country"
                            disabled
                            tabIndex={7}
                          />
                        </BootstrapForm.Group>
                      </Col>
                    </Row>

                    <h5>Contact Info</h5>
                    <Row>
                      <Col>
                        <BootstrapForm.Group controlId="cellphone">
                          <FormControl
                            type="text"
                            name="cellphone"
                            placeholder="Cell Phone"
                            tabIndex={8}
                          />
                        </BootstrapForm.Group>
                      </Col>
                      <Col>
                        <BootstrapForm.Group controlId="email">
                          <FormControl
                            type="text"
                            name="email"
                            placeholder="Email Address"
                            tabIndex={8}
                          />
                        </BootstrapForm.Group>
                      </Col>
                    </Row>

                    <h5>Employed/Unemployed</h5>
                    <Row>
                      <Col>
                        <RadioButtons control="radio" name="estatus" options={radioOptions} />
                      </Col>
                    </Row>
                    <br />
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_SITEKEY}
                      onChange={(value) => {
                        setFieldValue("recaptcha", value);
                      }}
                    />
                    <br />
                    <Button
                      type="submit"
                      tabIndex={3}
                      block
                      disabled={!(dirty && isValid) || isSubmitting}
                    >
                      {isSubmitting ? <Spinner animation="border" size="sm" /> : "Submit"}
                    </Button>
                  </Form>
                )}
              </Formik>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Earn450;
