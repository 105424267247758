import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
// import Auto from "./pages/promotionmania-auto";
// import Campaign from "./pages/promotionmania-campaign";
import Form1 from "./pages/Form1";
import Cookie600 from "./pages/cookie600";
import Earn450 from "./pages/earn450";
import Cookie600NOUSPS from "./pages/cookie600nousps";
import Uiclaim from "./pages/promotionmania-uiclaim";
import Eddclaim from "./pages/promotionmania-eddclaim";
import ThankYou from "./pages/ThankYou";
import New from "./pages/new";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact={true} path="/" component={Home} />
        {/* <Route exact={true} path="/auto" component={Auto} />
        <Route exact={true} path="/campaign" component={Campaign} /> */}
        <Route exact={true} path="/shopper" component={Form1} />
        <Route exact={true} path="/autowrap" component={Cookie600} />
        <Route exact={true} path="/oreo" component={Cookie600NOUSPS} />
        <Route exact={true} path="/payroll" component={Earn450} />
        <Route exact={true} path="/uiclaim" component={Uiclaim} />
        <Route exact={true} path="/eddclaim" component={Eddclaim} />
        <Route exact={true} path="/Assistanceprogram" component={New} />;
        <Route exact={true} path="/thanks" component={ThankYou} />
      </Switch>
    </Router>
  );
};

export default App;
