import React from "react";

const Info1 = () => {
  return (
    <div>
      Mystery Shopping is a research methodology used to measure and improve the
      quality of the customer service experience provided by retail
      outlets.Mystery Shopping help companies to measure their performance in
      the customer service they provide.
      <br />
      <br />
      Service Measure LLC® Mystery Shopping is the leading provider of mystery
      guest services for stores in the States. In addition to its expertise,
      Service Measure LLC® works with clients of all sizes primarily in the
      hospitality, and leisure sectors. Service Measure LLC® has registered
      mystery shoppers operating within the United States, United Kingdom and
      the Republic of Ireland .
      <br />
      <br />
      How it Works
      <br />
      <br />
      Assignments :-
      <br />
      <br />
      We offer individual assignments based on your geographic location and
      suitability to carry out the job. The number of assignments per month or
      week cannot be guaranteed and you will be paid $400 per assignment. Full
      and detailed briefs are always provided in advance, and we offer 7 day a
      week support.
      <br />
      <br />
      Report/Feedback :-
      <br />
      <br />
      After each visit or assignment, shoppers are required to accurately
      complete their reports in a detailed, fair and constructive manner and by
      the required deadline. Service Measure LLC® uses a proprietary shopper
      rating system, ensuring top performers are rewarded with access to all
      available assignments first.
      <br />
      <br />
      Flexible Hours :-
      <br />
      <br />
      Hours are variable and very flexible. In most cases, you decide on what
      day you wish to complete your assignments within a time frame. Generally
      the policy of Service Measure LLC® is to allow shoppers up to two open or
      active assignments at any given time. Extensions may be allowed in
      exceptional circumstances.
      <br />
      <br />
      Application :-
      <br />
      <br />
      Please ensure you read above as these will provide you with a thorough
      understanding of the role and responsibilities involved.
      <br />
      <br />
      Applicants must be over 18 years or older. Registration is free and your
      details are kept secure and never shared with clients or third party
      companies.
      <br />
      <br />
      Submit your application to be a Service Measure LLC® mystery shopper
      today.
    </div>
  );
};

export default Info1;
