import React, { useEffect } from "react";
import { Container } from "react-bootstrap";

const ThankYou = () => {
  useEffect(() => {
    document.title = "Promotion Mania - Thank you";
  }, []);
  return (
    <Container>
      <div className="text-center display-2 font-weight-bold text-success">
        Thank you for your submission, Our team will get back to you with
        further update.
      </div>
    </Container>
  );
};

export default ThankYou;
