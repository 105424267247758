import React from "react";
import { Form } from "react-bootstrap";
import { useField } from "formik";

const FormControl = ({ ...props }) => {
  const [field, meta] = useField(props);

  return (
    <React.Fragment>
      <Form.Control
        isInvalid={meta.touched && meta.error}
        {...field}
        {...props}
        autoComplete={"" + Math.random()}
      />
      <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
    </React.Fragment>
  );
};

export default FormControl;
