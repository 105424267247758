import React, { useEffect } from "react";
import { Container } from "react-bootstrap";

const Home = () => {
  useEffect(() => {
    document.title = "Promotion Mania";
  }, []);
  return (
    <Container>
      <div className="text-center display-2 font-weight-bold text-dark">
        Promotion Mania
      </div>
    </Container>
  );
};

export default Home;
